<template>
    <section class="w-50">
        <h1 class="mb-2">{{$t('navigation.logs')}}</h1>

        <FormItem id="created_at" label="Datum" v-model="item.formatted_date" :disabled="true" />
        <FormItem id="uri" label="URI" v-model="item.uri" :disabled="true" />
        <FormItem id="controller" label="Module" v-model="item.controller" :disabled="true" />
        <FormItem id="method" label="Actie" v-model="item.method" :disabled="true" />
        <FormItem id="user" label="Gebruiker" v-model="item.user.name" :disabled="true" />
        <FormItem id="user_agent" label="User agent" v-model="item.user_agent" :disabled="true" />
        <FormItem id="ip" label="IP" v-model="item.ip" :disabled="true" />
        <FormItem id="body" label="Aanvraag" v-model="item.body" type="textarea" :disabled="true" />

        
    </section>
    <section class="w-100">

        <h2 class="pt-2 pb-1">{{$t('logs.events')}}</h2>
        <div v-for="event in item.logs" :key="event.id" style="border-bottom: 1px solid #dee2e6;" class="mb-2">

            <div class="w-50">
                <FormItem id="class" label="Model" v-model="event.class" :disabled="true" />
                <FormItem id="function" label="Function" v-model="event.function" :disabled="true" />
            </div>
            <template v-if="event.diff_from || event.diff_to">
                <div class="row mb-2">
                    <div class="col-sm-6 col-form-label-sm">
                        {{$t('logs.from')}}<br>
                            <pre>{{event.diff_from}}</pre>
                    </div>
                    <div class="col-sm-6 col-form-label-sm">
                        {{$t('logs.to')}}<br>
                        <pre>{{event.diff_to}}</pre>
                    </div>
                </div>
            </template>

            <div class="row mb-2">
                <div class="col-sm-6 col-form-label-sm">
                    {{$t('logs.before')}}<br>
                    <pre>{{event.before_state}}</pre>
                </div>
                <div class="col-sm-6 col-form-label-sm">
                    {{$t('logs.after')}}<br>
                    <pre>{{event.after_state}}</pre>
                </div>
            </div>
        </div>
    </section>
    <FooterButtons buttons="1" @prev="prev" />
</template>

<script>
    import logService from '@/services/LogService';
    import FooterButtons from '@/components/FooterButtons.vue';
    export default {
        name: 'LogDetail',
        components: {
            FooterButtons
        },
        data() {
            return {
                item: {
                    request_body: "",
                    user: {}
                }
            }
        },
        methods: {
            prev() {
                this.$router.push({'name': "Logs"});
            }
        },
        mounted(){
            const logId = this.$route.params.id;
            logService.show(logId).then(response => {
                this.item = response.data;
                this.item.formatted_date = this.formatDate(this.item.created_at, 'dateTime');
            });
        }
    }

</script>